import React, { useEffect, useState, useRef } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import downloadfiles from '@/utils/downloadfile.js'
import style from './index.module.less'
import classNames from 'classnames'
import { TouMingBaiBtn } from '@/components/index'
import {
  cinemaAppHeader,
  cinemaDetail1,
  cinemaDetail2,
  cinemaDetail3,
  cinemaDetail4,
  cinemaDetail5,
  cinemaDetail6,
  cinemaDetail7,
  cinemaMacDetail1,
  cinemaMacDetail2,
  cinemaMacDetail3,
  cinemaMacDetail4,
  cinemaMacDetail5,
  cinemaMacDetail6,
  cinemaMacDetail7,
  cinemaMacDetail8,
  cinemaMacDetail9,
  cinemaMacDetail10,
  cinemaMacDetail11,
} from '@/images/index'
import {
  phoneIcon,
  ppIcon,
  ttIcon,
  emailIcon,
  urlIcon,
  userIcon,
  closeIcon,
  supportIcon,
  playVideoIcon
} from '@/images/arlab/index'
import img_1 from "@/images/arlab/nebula-for-mac/spatial.png"
import { DetailCaroul, DetailPic } from "@/components/Arlab/index"
import pic4 from "@/images/image_dots.svg"
import { useIntl } from "react-intl"
import useNavigate from '@/hooks/useNavigate'

const data = {
  id: 1,
  icon: img_1,
  name: "Spatial Cinema",
  downloadUrl: `${process.env.resourceUrl}package/Spatial Cinema v1.0_14.zip`,
  description: "Spatial Cinema is a media player that allows you to watch both 2D and 3D local video content on a massive Spatial Display using XREAL Air.<br/><br/>Developed with our NRSDK beta for macOS, you have the freedom choose between a traditional flat screen, or a more immersive curved screen experience.<br/><br/>Interfacing with Spatial Cinema is as simple as using your MacBook's trackpad to adjust the size and distance, along with tapping the control button to re-center the screen.",
  conceptImages: [
    cinemaMacDetail1,
    cinemaMacDetail2,
    cinemaMacDetail3,
    cinemaMacDetail4,
    cinemaMacDetail5,
    cinemaMacDetail6,
    cinemaMacDetail7,
    cinemaMacDetail8,
    cinemaMacDetail9,
    cinemaMacDetail10,
    cinemaMacDetail11,
  ],
  supportDevice: ['Air'],
  promotionalInfo: {
    type: "Image",
    url: cinemaAppHeader
  },
  additionalDetail: { apkReleaseDate: "July 06, 2023", apkSize: "364.5 MB", apkVersion: "1.0", lang: "English" }, developerInfo: { name: null, phoneNumber: null, email: "design@xreal.com", devWebsite: 'https://www.xreal.com/', policy: null, termsService: null }
}


const Index = () => {
  const intl = useIntl()
  const [ifBigPic, setIfBigPic] = useState(false)
  const [ifBigVideo, setIfBigVideo] = useState(false)
  const [bigPicIndex, setBigPicIndex] = useState(0)

  const downloadApk = (src) => {
    downloadfiles(src)
  }
  const openBigPic = (index) => {
    setBigPicIndex(index)
    setIfBigPic(true)
  }
  const goBack = () => {
    useNavigate('/arlab')
  }



  return (
    <Layout menuBlack={false}>
      <SEO page="AR_Lab" />
      <div style={{ backgroundColor: "#111" }}>

        {ifBigPic && <div className={style.bigPicBox} >
          <DetailCaroul bigPicIndex={bigPicIndex} picList={data.conceptImages} />
          <img src={closeIcon} className={style.closeBtn} onClick={() => setIfBigPic(false)} />
        </div>}
        {ifBigVideo &&
          <div className={style.bigPicBox} >
            <div className={style.bigPicVideo}>
              <video autoPlay width="100%" controls playsInline>
                <source src={data.promotionalInfo.url} type="video/mp4" />
              </video></div>

            <img src={closeIcon} className={style.closeBtn} onClick={() => setIfBigVideo(false)} />
          </div>
        }
        {/* promotionalInfo type */}

        <div className={style.header}>
          <div className={style.goBack} onClick={() => { goBack() }}></div>
          <div className={style.headerCover}></div>
          {data.promotionalInfo.type == 'Video' ?
            <div className={style.headerVideo} onClick={() => { setIfBigVideo(true) }}>
              <video autoPlay loop muted width="100%" playsInline>
                <source src={data.promotionalInfo.url} type="video/mp4" />
              </video>
              <div className={style.videoMask}
                style={{ backgroundImage: `url(${pic4})` }}
              ></div>
            </div>
            :
            <div className={style.headerImg} style={{ backgroundImage: `url(${data.promotionalInfo.url})` }}></div>
          }

        </div>
        <div className={style.detail}>
          <div className={style.appName}>
            {data.name}
          </div>

          <div className={style.icon} style={{ backgroundImage: `url(${data.icon})` }}>

          </div>
          <div className={style.tagBox}>
            {data.supportDevice.indexOf("Air") > -1 && <div className={classNames(style.tag)}>Air<img src={supportIcon} /></div>}
            {data.supportDevice.indexOf("Light") > -1 && <div className={classNames(style.tag)}>light<img src={supportIcon} /></div>}

          </div>
          <div className={style.downloadBtn} onClick={() => downloadApk(data.downloadUrl)}>
            <TouMingBaiBtn>{intl.formatMessage({ id: "nebulaPage.text24", defaultMessage: "Download" })}</TouMingBaiBtn>
          </div>
          <DetailPic openBigPic={openBigPic} picList={data.conceptImages} />
          <div className={style.des}>
            <div className={style.desTitle}>
              {intl.formatMessage({ id: "arlab_des", defaultMessage: "Description" })}
            </div>
            <div className={style.desText} dangerouslySetInnerHTML={{ __html: `${data.description}`, }}>

            </div>
          </div>
          <div className={style.addition}>
            <div className={style.additionTitle}>
              {intl.formatMessage({ id: "arlab_addition", defaultMessage: "Additional Details" })}
            </div>
            <div className={style.additionItem}>
              <div className={style.additionItem_key}>
                {intl.formatMessage({ id: "arlab_des_lang", defaultMessage: "Languages" })}
              </div>
              <div className={style.additionItem_val}>{data.additionalDetail.lang}</div>
            </div>
            <div className={style.additionItem}>
              <div className={style.additionItem_key}>
                {intl.formatMessage({ id: "arlab_des_size", defaultMessage: "Space Required" })}
              </div>
              <div className={style.additionItem_val}>{data.additionalDetail.apkSize}</div>
            </div>
            <div className={style.additionItem}>
              <div className={style.additionItem_key}>
                {intl.formatMessage({ id: "arlab_des_version", defaultMessage: "Version" })}
              </div>
              <div className={style.additionItem_val}>{data.additionalDetail.apkVersion}</div>
            </div>
            <div className={style.additionItem}>
              <div className={style.additionItem_key}>
                {intl.formatMessage({ id: "arlab_des_date", defaultMessage: "Release Date" })}
              </div>
              <div className={style.additionItem_val}>{data.additionalDetail.apkReleaseDate}</div>
            </div>
          </div>
          <div className={style.info}>
            <div className={style.infoTitle}>
              {intl.formatMessage({ id: "arlab_des_developer", defaultMessage: "Developer Info" })}
            </div>
            {data.developerInfo.name && <div className={style.infoItem}>
              <img src={userIcon} />
              <span>{data.developerInfo.name}</span>
            </div>}
            {data.developerInfo.email && <div className={style.infoItem}>
              <img src={emailIcon} />
              <span>{data.developerInfo.email}</span>
            </div>}
            {data.developerInfo.phoneNumber && <div className={style.infoItem}>
              <img src={phoneIcon} />
              <span>{data.developerInfo.phoneNumber}</span>
            </div>}
            {data.developerInfo.devWebsite && <div className={style.infoItem}>
              <img src={urlIcon} />
              <span><a target='_blank' href={data.developerInfo.devWebsite}>
                {intl.formatMessage({ id: "arlab_des_web", defaultMessage: "Developer Website" })}
              </a></span>
            </div>}
            {data.developerInfo.policy && <div className={style.infoItem}>
              <img src={ppIcon} />
              <span><a target='_blank' href={data.developerInfo.policy}>
                {intl.formatMessage({ id: "footer.privacy", defaultMessage: "Privacy Policy" })}
              </a></span>
            </div>}
            {data.developerInfo.termsService && <div className={style.infoItem}>
              <img src={ttIcon} />
              <span><a target='_blank' href={data.developerInfo.termsService}>
                {intl.formatMessage({ id: "footer.terms", defaultMessage: "Terms of Service" })}
              </a></span>
            </div>}
          </div>

        </div></div>

    </Layout>


  )
}
export default Index