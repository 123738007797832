import React ,{useState} from 'react'
import Slider from "react-slick";
import './index.css'

const ArrowLeft =  ({currentSlide, slideCount, children, ...props})=>(
    <button {...props} >  </button>
)
 const ArrowRight =  ({currentSlide, slideCount, children, ...props})=>(
    <button  {...props}></button>

)
const DetailCaroul = ({bigPicIndex,picList}) => {
    const settings = {
        initialSlide:bigPicIndex,
        // arrows: false,
        dots: true,
        fade:true,
        infinite: false,
        className: 'arlabDeteailSwiper',
        speed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <ArrowLeft />,
        nextArrow: <ArrowRight />,
        
    };
    return (
        <div style={{width:'100vw'}}>
            <Slider {...settings} >
                {picList.map((item,index)=>(
                    <div class='picBox' key={`detailCaroul${index}`}>
                    <div style={{backgroundImage:`url(${item})`,paddingBottom:"50%",backgroundSize:"cover",backgroundPosition:"center center",borderRadius:'18px'}} ></div>
                </div>
                ))}
                
            </Slider>
        </div>
    )
}

export default DetailCaroul