import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "./style.css";
import style from './index.module.less'
import { Mousewheel, FreeMode } from "swiper";

const DetailPic = ({ openBigPic, picList }) => {
    const [ifStart, setIfStart] = useState(true)
    const [ifEnd, setIfEnd] = useState(false)
    return (
        <div className={style.picBox} style={{ display: 'flex' }}>
            <div className={style.picLeft}
                style={{ opacity: ifStart ? '0' : '1' }}
            ></div>
            <div className={style.picRight}
                style={{ opacity: ifEnd ? '0' : '1' }}
            ></div>
            <Swiper
                slidesPerView='auto'
                freeMode={true}
                mousewheel={true}
                modules={[FreeMode]}
                spaceBetween={16}
                loop={false}

                onSlideChange={() => {
                    setIfStart(false)
                    setIfEnd(false)
                }}
                onTouchMove={(swiper1) => {
                    if (swiper1.isBeginning) {
                        setIfStart(true)
                    } else {
                        setIfStart(false)
                    }
                    if (swiper1.isEnd) {
                        setIfEnd(true)
                    } else {
                        setIfEnd(false)
                    }
                }}
                onReachBeginning={(swiper) => {
                    setIfStart(true)
                }}
                onReachEnd={() => {
                    setIfEnd(true)
                }}
                className="arlabDetailSwiper"
                onClick={(swiper2) => {
                    openBigPic(swiper2.clickedIndex)
                }}
            >
                {picList.map((item, index) => (
                    <SwiperSlide key={`dtailPic${index}`}>
                        <div className='detailPicItem' style={{backgroundImage:`url(${item})`}}></div>
                    </SwiperSlide>
                ))}

            </Swiper>
        </div>)
}

export default DetailPic